export const classNames = (...args: (string | number | boolean | null | undefined)[]): string => {
    const classes: (string | number | boolean)[] = [];
    for (let i = 0; i < args.length; i += 1) {
        const arg = args[i];
        if (!arg) continue;
        const argType = typeof arg;
        if (argType === 'string' || argType === 'number') {
            classes.push(arg);
        }
    }
    return classes.join(' ');
};
