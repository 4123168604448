// OAuth login
export const LOGIN_FROM_OAUTH_CODE_REQUEST = 'LOGIN_FROM_OAUTH_CODE_REQUEST';
export const LOGIN_FROM_OAUTH_CODE_SUCCESS = 'LOGIN_FROM_OAUTH_CODE_SUCCESS';
export const LOGIN_FROM_OAUTH_CODE_FAILURE = 'LOGIN_FROM_OAUTH_CODE_FAILURE';

interface ILoginFromOAuthCodeRequest {
    type: typeof LOGIN_FROM_OAUTH_CODE_REQUEST;
    payload: any;
}

interface ILoginFromOAuthCodeSuccess {
    type: typeof LOGIN_FROM_OAUTH_CODE_SUCCESS;
    payload: any;
}

interface ILoginFromOAuthCodeFailure {
    type: typeof LOGIN_FROM_OAUTH_CODE_FAILURE;
    payload: any;
}

// logout
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

interface ILogoutSuccess {
    type: typeof LOGOUT_SUCCESS;
    payload: any;
}

// currently logged in user's details
export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';

interface IFetchCurrentUserRequest {
    type: typeof FETCH_CURRENT_USER_REQUEST;
    payload: any;
}

interface IFetchCurrentUserSuccess {
    type: typeof FETCH_CURRENT_USER_SUCCESS;
    payload: any;
}

interface IFetchCurrentUserFailure {
    type: typeof FETCH_CURRENT_USER_FAILURE;
    payload: any;
}

export const GET_OAUTH_URL_REQUEST = 'GET_OAUTH_URL_REQUEST';
export const GET_OAUTH_URL_SUCCESS = 'GET_OAUTH_URL_SUCCESS';
export const GET_OAUTH_URL_FAILURE = 'GET_OAUTH_URL_FAILURE';

interface IGetOAuthUrlRequest {
    type: typeof GET_OAUTH_URL_REQUEST;
    payload: any;
}

interface IGetOAuthUrlSuccess {
    type: typeof GET_OAUTH_URL_SUCCESS;
    payload: any;
}

interface IGetOAuthUrlFailure {
    type: typeof GET_OAUTH_URL_FAILURE;
    payload: any;
}

export type authActionTypes =
    | ILoginFromOAuthCodeRequest
    | ILoginFromOAuthCodeSuccess
    | ILoginFromOAuthCodeFailure
    | ILogoutSuccess
    | IFetchCurrentUserRequest
    | IFetchCurrentUserSuccess
    | IFetchCurrentUserFailure
    | IGetOAuthUrlRequest
    | IGetOAuthUrlSuccess
    | IGetOAuthUrlFailure;
