import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import MenuApp from './components/MenuApp/MenuApp';
const AuthPage = lazy(() => import('./pages/AuthPage/AuthPage'));
const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const CalculatorPage = lazy(() => import('./pages/CalculatorPage/CalculatorPage'));
import ErrorBoundary from './components/ErrorBoundry/ErrorBoundry';
import RequireAuth from './hoc/RequireAuth';

const App: React.FC = () => {
    return (
        <div>
            <MenuApp />
            <Switch>
                <ErrorBoundary>
                    <Suspense fallback={<p>Loading</p>}>
                        <Route exact path="/" component={HomePage} />
                        <Route exact path="/auth" component={AuthPage} />
                        <Route exact path="/calculator" component={RequireAuth(CalculatorPage)} />
                    </Suspense>
                </ErrorBoundary>
            </Switch>
            <ToastContainer
                position="bottom-left"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default App;
