export const LOGIN_FROM_OAUTH_CODE = '/auth?code=';
export const CURRENT_USER = '/user/me';
export const OAUTH_URL = '/auth/get-url';

const authApiConstants = {
    LOGIN_FROM_OAUTH_CODE,
    CURRENT_USER,
    OAUTH_URL
};

export default authApiConstants;
