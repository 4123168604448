import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { fetchCurrentUser } from '../redux/actions/authActions';
import { IApplicationState } from '../redux/reducers/IApplicationState';

const RequireAuth = (Component: any) => {
    const RequireAuthComponent = () => {
        const dispatch = useDispatch();
        const currentUser = useSelector((state: IApplicationState) => state.auth.user);
        useEffect(() => {
            dispatch(fetchCurrentUser());
        }, []);

        if (currentUser.email) {
            return <Component />;
        } else {
            return <Redirect to="/" />;
        }
    };
    return RequireAuthComponent;
};

export default RequireAuth;
