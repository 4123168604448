import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import history from './utils/history';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            {/*todo read about loading and onBeforeLift props for PersistGate
            https://github.com/rt2zz/redux-persist/blob/master/docs/PersistGate.md
        */}
            <PersistGate persistor={persistor}>
                <Router history={history}>
                    <App />
                </Router>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
