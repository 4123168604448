import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import get from 'lodash/get';
import { toast } from 'react-toastify';

import { LOGOUT_SUCCESS } from '../redux/actionTypes/authActionTypes';
import { store } from '../redux/store';
import CustomError from './customError/customError';
import ICustomError from './customError/ICustomError';
import history from './history';
import storage from './storage';

const instance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    }
});

export const toastError = (errorToShow: ICustomError): void => {
    toast.error(errorToShow.message, {
        position: toast.POSITION.BOTTOM_LEFT
    });
};

export const toastSuccess = (messageToShow: ICustomError): void => {
    toast.success(messageToShow);
};

export const toastWarning = (messageToShow: ICustomError): void => {
    toast.warn(messageToShow);
};

export const setAccessTokenHeader = (accessToken: string): void => {
    instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const deleteAccessTokenHeader = (): void => {
    instance.defaults.headers.common.Authorization = null;
};

export const getAccessTokenFromHeader = (): string =>
    instance.defaults.headers.common.Authorization;

export const logout = async (): Promise<void> => {
    try {
        await storage.removeItem('token');
        await storage.removeItem('user');
        deleteAccessTokenHeader();
        store.dispatch({
            type: LOGOUT_SUCCESS,
            payload: undefined
        });
        history.push('/');
    } catch (e) {
        // logout error
    }
};

instance.interceptors.request.use(async (request: AxiosRequestConfig) => {
    const token = await storage.getItem('token');
    if (token) {
        request.headers.common.Authorization = `Bearer ${token}`;
    }
    return request;
});

// send success message using toast here if found any
instance.interceptors.response.use(
    (response: AxiosResponse) => {
        const message = get(response, 'data.message');
        if (message) toastSuccess(message);
        return response.data;
    },
    async (error: any) => {
        const status = get(error, 'response.status');
        const errorKey = get(error, 'response.data.errorKey');
        const message = get(error, 'response.data.message');
        const payload = get(error, 'response.data.payload');

        // general error message
        let errorToShow: ICustomError = new CustomError(
            'Oops! Something went wrong. Please contact customer support for further assistance.',
            errorKey,
            payload
        );

        if (status === 401) {
            await logout();
            // errorToShow = new CustomError(message, errorKey, payload);
            // toastError(errorToShow);
        }
        if (status === 403) {
            await logout();
            // errorToShow = new CustomError(message, errorKey, payload);
            // toastError(errorToShow);
        } else if (status === 422) {
            // errors to show on UI
            errorToShow = new CustomError(message, errorKey, payload);
            toastError(errorToShow);
        } else if (status === 404) {
            // errorToShow = new CustomError(message, errorKey, payload);
            // toastError(errorToShow);
        } else if (status === 400) {
            errorToShow = new CustomError(message, errorKey, payload);
            toastError(errorToShow);
        }

        return Promise.reject(errorToShow);
    }
);

export default instance;
