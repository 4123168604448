import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOAuthUrl, logout } from '../../redux/actions/authActions';
import { IApplicationState } from '../../redux/reducers/IApplicationState';
import { classNames as cn } from '../../utils/classNames';
import { aboutUs, ourProducts } from './elements';
import styles from './MenuAppStyle.module.scss';
const style: any = styles;

export interface TMenuApp {
    buttonClass: string;
    productClass: string;
    aboutClass: string;
    profileSubmenuClass: string;
}

const Navbar: React.FC = () => {
    const dispatch = useDispatch();
    const oAuthUrl = useSelector((state: IApplicationState) => state.auth.oAuthUrl);
    const currentUser = useSelector((state: IApplicationState) => state.auth.user);
    const [classes, setClasses] = useState<TMenuApp>({
        buttonClass: '',
        productClass: 'hide',
        aboutClass: 'hide',
        profileSubmenuClass: ''
    });

    const bodyListener = (e: MouseEvent) => {
        const target = e.target as HTMLElement;

        if (
            target &&
            target.parentElement &&
            !target.parentElement.classList.contains('js-class-helper')
        ) {
            setClasses({
                ...classes,
                productClass: 'hide',
                aboutClass: 'hide',
                profileSubmenuClass: ''
            });
        }
    };

    useEffect(() => {
        dispatch(getOAuthUrl());
        document.body.addEventListener('click', bodyListener);
    }, []);

    const buttonClick = () => {
        setClasses({
            ...classes,
            buttonClass: !classes.buttonClass ? 'active' : ''
        });
        document.body.classList.toggle('locked');
    };

    const profileSubmenuClick = () => {
        setClasses({
            ...classes,
            productClass: 'hide',
            aboutClass: 'hide',
            profileSubmenuClass: classes.profileSubmenuClass ? 'active' : ''
        });
    };

    const listClick = (e: React.MouseEvent, name: 'productClass' | 'aboutClass') => {
        const target = e.target as HTMLElement;
        const targetHasLink =
            target.getAttribute('href') ||
            (target.parentElement && target.parentElement.getAttribute('href'));

        if (!targetHasLink) {
            e.preventDefault();
            e.stopPropagation();
            const isActive = classes[name] === 'active';
            setClasses({
                ...classes,
                productClass: `hide ${!isActive && 'disable-hover'}`,
                aboutClass: `hide ${!isActive && 'disable-hover'}`,
                profileSubmenuClass: '',
                [name]: isActive ? 'hide' : 'active'
            });
        } else {
            setClasses({
                buttonClass: '',
                productClass: 'hide',
                aboutClass: 'hide',
                profileSubmenuClass: ''
            });
        }
    };

    const getClasses = (classes: string) => {
        const array = classes.split(' ');
        return array.map((el) => style[el].join(' '));
    };

    const renderList = (listType: string) => {
        const elements = listType === 'ourProducts' ? aboutUs : ourProducts(true, true);
        aboutUs;

        return (
            <ul className={style['submenu']}>
                {elements.map((el, index) => (
                    <li
                        key={index}
                        className={cn(style['micon'], style[el.class], style['menu-item'])}>
                        <a href={el.link}>
                            {el.name}
                            <p className={style['menu-item-description']}>{el.desc}</p>
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    const renderProfilesOption = () => (
        <>
            <div className={cn(style['menu-item'], style['profile-options'], 'js-class-helper')}>
                <li className={cn(style['name'], 'js-class-helper')} onClick={profileSubmenuClick}>
                    {currentUser.publicName ? currentUser.publicName : ''}
                    <ul className={cn(style['profile-submenu'], style['profile-submenu-class'])}>
                        {renderSubmenuListItems()}
                    </ul>
                </li>
            </div>
            <li className={cn(style['menu-item'], style['menu-item-mobile'])}>
                <a href={`/users/sign_out`}>Logout</a>
            </li>
        </>
    );
    const renderSubmenuListItems = () => (
        <>
            {/* <li className={style.item}>
                <a href={`/profile/${this.props.user.slug}/account`}>View Profile</a>
            </li>

            <li className={style.item}>
                <a href={`/profile/${this.props.user.slug}/portfolio`}>Portfolio</a>
            </li>
            <li className={style.item}>
                <a href={`/profile/${this.props.user.slug}/funds`}>Funds</a>
            </li>
            <li className={style.item}>
                <a href={`/profile/${this.props.user.slug}/security`}>Security</a>
            </li>

            <li className={cn(style.item, style.itemMobile)}>
                <a href={`/profile/${this.props.user.slug}/membership`}>Membership</a>
            </li>
            <li className={cn(style.item, style.itemMobile)}>
                <a href={`/profile/${this.props.user.slug}/companies`}>My companies</a>
            </li>
            <li className={cn(style.item, style.itemMobile)}>
                <a href={`/profile/${this.props.user.slug}/pitches`}>My pitches</a>
            </li>

            <li className={cn(style['item'])}>
                <a href={`/redirect_to_identity`}>Identity</a>
            </li>
            <li className={style['item']}>
                <a href={`/support`}>Support</a>
            </li> */}
            <li className={style['item']}>
                <a href={`#/`} onClick={() => dispatch(logout())}>
                    Logout
                </a>
            </li>
        </>
    );

    return (
        <header className={cn(style['header'], 'js-header')}>
            <a
                href={
                    currentUser.publicName
                        ? `//${process.env.REACT_APP_DOMAIN}/profile`
                        : process.env.REACT_APP_HOMEPAGE_DOMAIN
                }
                className={style['header-logo']}
            />
            <div
                className={cn(
                    style['navigation'],
                    style['hidden-portable'],
                    style[classes.buttonClass],
                    'js-menu'
                )}>
                <nav className={style['nav-main']}>
                    <div>
                        <ul className={style['menu']}>
                            <li
                                className={cn(
                                    style['menu-item'],
                                    style['menu-item-has-children'],
                                    //getClasses(classes.productClass),
                                    'js-class-helper'
                                )}
                                onClick={(e) => listClick(e, 'productClass')}>
                                <a>Our products</a>
                                {renderList('ourProducts')}
                            </li>
                            <li
                                className={cn(
                                    style['menu-item'],
                                    style['menu-item-has-children'],
                                    //getClasses(classes.aboutClass),
                                    'js-class-helper'
                                )}
                                onClick={(e) => listClick(e, 'aboutClass')}>
                                <a>About us</a>
                                {renderList('aboutUs')}
                            </li>
                            <li className={style['menu-item']}>
                                <a href={`${process.env.REACT_APP_HOMEPAGE_DOMAIN}/blog/`}>
                                    Announcements
                                </a>
                            </li>
                            <li className={style['menu-item']}>
                                <a href={`${process.env.REACT_APP_HOMEPAGE_DOMAIN}/support`}>
                                    Support
                                </a>
                            </li>
                            {currentUser.publicName ? (
                                renderProfilesOption()
                            ) : (
                                <>
                                    <li
                                        className={cn(
                                            style['mside'],
                                            style['m-side-btn'],
                                            style['menu-item']
                                        )}>
                                        <a href={`//${process.env.REACT_APP_DOMAIN}/join`}>
                                            Join Us
                                        </a>
                                    </li>
                                    <li
                                        className={cn(
                                            style['mside'],
                                            //style['mside-simple'],
                                            style['menu-item'],
                                            style['mside-btn']
                                        )}>
                                        <a href={oAuthUrl} target="_blank" rel="noreferrer">
                                            {' '}
                                            Sign in via BnkToTheFuture.com
                                        </a>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </nav>
            </div>
            <button
                className={cn(style['header-menu'], style[classes.buttonClass])}
                onClick={buttonClick}>
                <span className={style['header-menu-line']} />
            </button>
        </header>
    );
};

Navbar.displayName = 'Navbar';

export default Navbar;
