import { applyMiddleware, createStore, Middleware, Store } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './reducers/rootReducer';

const middlewares: Array<Middleware> = [thunk];

const logger = createLogger({
    timestamp: true
});

if (process.env.NODE_ENV !== 'production') {
    middlewares.push(logger);
}

export const store: Store = createStore(rootReducer, applyMiddleware(...middlewares));
export const persistor = persistStore(store);

const storeObject = { store, persistor };

export default storeObject;
