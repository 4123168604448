import {
    authActionTypes,
    FETCH_CURRENT_USER_FAILURE,
    FETCH_CURRENT_USER_REQUEST,
    FETCH_CURRENT_USER_SUCCESS,
    GET_OAUTH_URL_FAILURE,
    GET_OAUTH_URL_REQUEST,
    GET_OAUTH_URL_SUCCESS,
    LOGIN_FROM_OAUTH_CODE_FAILURE,
    LOGIN_FROM_OAUTH_CODE_REQUEST,
    LOGIN_FROM_OAUTH_CODE_SUCCESS,
    LOGOUT_SUCCESS
} from '../actionTypes/authActionTypes';

export interface IUser {
    publicName: string;
    email: string;
    isActive: boolean;
}
export interface IAuthInitialState {
    user: IUser;
    token: string;
    oAuthUrl: string;
    isLoggedIn: boolean;
}

const initialState: IAuthInitialState = {
    user: {
        publicName: '',
        email: '',
        isActive: false
    },
    token: '',
    oAuthUrl: '',
    isLoggedIn: false
};

const authReducer = function (state = initialState, action: authActionTypes) {
    switch (action.type) {
        case GET_OAUTH_URL_REQUEST: {
            return {
                ...state,
                oAuthUrl: ''
            };
        }
        case GET_OAUTH_URL_SUCCESS: {
            return {
                ...state,
                oAuthUrl: action.payload.url
            };
        }
        case GET_OAUTH_URL_FAILURE: {
            return {
                ...state,
                oAuthUrl: ''
            };
        }
        case LOGIN_FROM_OAUTH_CODE_REQUEST: {
            return {
                ...state,
                user: {}
            };
        }
        case LOGIN_FROM_OAUTH_CODE_SUCCESS: {
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token
            };
        }
        case LOGIN_FROM_OAUTH_CODE_FAILURE: {
            return {
                ...state,
                user: {}
            };
        }
        case FETCH_CURRENT_USER_REQUEST: {
            return {
                ...state
            };
        }
        case FETCH_CURRENT_USER_SUCCESS: {
            return {
                ...state,
                user: action.payload.user,
                isLoggedIn: true
            };
        }
        case FETCH_CURRENT_USER_FAILURE: {
            return {
                ...state,
                isLoggedIn: false
            };
        }
        case LOGOUT_SUCCESS: {
            return {
                ...state,
                user: {},
                token: ''
            };
        }
        default:
            return state;
    }
};

export default authReducer;
