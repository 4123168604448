const asyncLocalStorage = {
    setItem: (key: string, value: string): Promise<void | null | undefined> =>
        Promise.resolve()
            .then(() => {
                localStorage.setItem(key, value);
            })
            .catch((e) => {
                console.log('Error while setting item', e);
            }),
    getItem: (key: string): Promise<string | void | null | undefined> =>
        Promise.resolve()
            .then(() => {
                return localStorage.getItem(key);
            })
            .catch((e) => {
                console.log('Error while getting item', e);
            }),
    removeItem: (key: string): Promise<void | null | undefined> =>
        Promise.resolve()
            .then(() => {
                localStorage.removeItem(key);
            })
            .catch((e) => {
                console.log('Error while removing item', e);
            })
};

export default asyncLocalStorage;
