export const ourProducts = (signedIn: boolean, organizational: boolean) => {
    const identityLink = signedIn
        ? `//${process.env.REACT_APP_DOMAIN}/profile`
        : `//${process.env.REACT_APP_DOMAIN}/users/sign_in`;

    const products = [
        {
            name: 'Start investing',
            desc: 'Invest in the latest FinTech & Crypto deals.',
            link: `//${process.env.REACT_APP_DOMAIN}/search/pitches`,
            class: 'micon-invest'
        },
        {
            name: 'Raise Money',
            desc: 'Apply for funding now.',
            link: `//${process.env.REACT_APP_DOMAIN}/apply-for-finance`,
            class: 'micon-money'
        },
        {
            name: 'Start Trading',
            desc: 'Buy & sell shares in FinTech & Crypto companies.',
            link: `//${process.env.REACT_APP_DOMAIN}/market`,
            class: 'micon-trade'
        },
        {
            name: 'BF Token',
            desc: 'Our Membership, Rewards & Payments Token – $BFT.',
            link: 'http://bf-token.bnktothefuture.com/',
            class: 'micon-token'
        },
        {
            name: 'BF Identity',
            desc: 'Verify your ID once & re-use on multiple investment platforms (Coming Soon).',
            link: identityLink,
            class: 'micon-about'
        },
        {
            name: 'Retirement Plan B (RPB)',
            desc: 'Subtext: Build and Protect Your Wealth in The Great Depression of the 2020’s.',
            link: 'http://retirementplanb.com',
            class: 'plan-b'
        }
    ];

    const skipForOrganization = ['Raise Money'];

    return organizational
        ? products.filter((prod) => !skipForOrganization.includes(prod.name))
        : products;
};

export const aboutUs = [
    {
        name: 'Portfolio Companies',
        desc: 'Learn more about the companies we have invested in.',
        link: `//${process.env.REACT_APP_DOMAIN}/companies`,
        class: 'micon-companies'
    },
    {
        name: 'About Us',
        desc: 'Learn more about the world’s first Crypto Securities business – ESt. 2010.',
        link: `//${process.env.REACT_APP_DOMAIN}/about`,
        class: 'micon-about'
    },
    {
        name: 'News',
        desc: 'Latest features, Announcements, Vlogs & more.',
        link: `${process.env.HOMEPAGE_REACT_APP_DOMAIN}/blog/`,
        class: 'micon-blog'
    },
    {
        name: 'BF Live',
        desc: 'Get your questions answered on our weekly live show.',
        link: 'https://www.youtube.com/banktothefuture%22?sub_confirmation=1',
        class: 'micon-community'
    },
    {
        name: 'Careers',
        desc: 'Join a fast-growing global virtual team building the future of finance.',
        link: `//${process.env.REACT_APP_DOMAIN}/careers`,
        class: 'micon-careers'
    }
];
