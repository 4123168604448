class CustomError extends Error {
    errorKey: any;
    payload: any;
    constructor(message: any, errorKey: any, payload = {}) {
        super(message);
        this.errorKey = errorKey;
        this.payload = payload;
    }
}

export default CustomError;
