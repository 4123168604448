import React, { Component, ErrorInfo, ReactNode } from 'react';

import styles from './ErrorBoundry.module.scss';
const style: any = styles;

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }
    public render() {
        if (this.state.hasError) {
            return (
                <div className={style['error-image-overlay']}>
                    <div className={style['error-image-container']} />
                    <h2 className={style['error-image-text']}>Sorry this page is broken</h2>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
