import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import history from '../../utils/history';
import axios, { deleteAccessTokenHeader, setAccessTokenHeader } from '../../utils/httpClient';
import storage from '../../utils/storage';
import {
    authActionTypes,
    FETCH_CURRENT_USER_FAILURE,
    FETCH_CURRENT_USER_REQUEST,
    FETCH_CURRENT_USER_SUCCESS,
    GET_OAUTH_URL_FAILURE,
    GET_OAUTH_URL_REQUEST,
    GET_OAUTH_URL_SUCCESS,
    LOGIN_FROM_OAUTH_CODE_FAILURE,
    LOGIN_FROM_OAUTH_CODE_REQUEST,
    LOGIN_FROM_OAUTH_CODE_SUCCESS,
    LOGOUT_SUCCESS
} from '../actionTypes/authActionTypes';
import { CURRENT_USER, LOGIN_FROM_OAUTH_CODE, OAUTH_URL } from '../apiConstants/authApiConstants';

const loginFromOAuthCodeRequest = (): authActionTypes => {
    return { type: LOGIN_FROM_OAUTH_CODE_REQUEST, payload: undefined };
};
const loginFromOAuthCodeSuccess = (payload: any): authActionTypes => {
    return { type: LOGIN_FROM_OAUTH_CODE_SUCCESS, payload };
};
const loginFromOAuthCodeFailure = (): authActionTypes => {
    return { type: LOGIN_FROM_OAUTH_CODE_FAILURE, payload: undefined };
};

export const loginFromOAuthCode = (
    code: string
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
    dispatch(loginFromOAuthCodeRequest());
    try {
        const response: any = await axios.get(`${LOGIN_FROM_OAUTH_CODE}${code}`);
        await storage.setItem('token', response.data.token);
        await storage.setItem('user', JSON.stringify(response.data.user));
        setAccessTokenHeader(response.token);
        dispatch(loginFromOAuthCodeSuccess(response.data));
        history.push('/calculator');
    } catch (error) {
        dispatch(loginFromOAuthCodeFailure());
    }
};

const fetchCurrentUserRequest = (): authActionTypes => {
    return { type: FETCH_CURRENT_USER_REQUEST, payload: undefined };
};
const fetchCurrentUserSuccess = (payload: any): authActionTypes => {
    return { type: FETCH_CURRENT_USER_SUCCESS, payload };
};
const fetchCurrentUserFailure = (payload: any): authActionTypes => {
    return { type: FETCH_CURRENT_USER_FAILURE, payload: undefined };
};
export const fetchCurrentUser = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
    dispatch(fetchCurrentUserRequest());
    try {
        const currentUser = await axios.get(CURRENT_USER);
        dispatch(fetchCurrentUserSuccess(currentUser));
    } catch (error) {
        dispatch(fetchCurrentUserFailure(error));
    }
};

const logoutSuccess = (): authActionTypes => {
    return { type: LOGOUT_SUCCESS, payload: undefined };
};
export const logout = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
    try {
        await storage.removeItem('token');
        await storage.removeItem('user');
        deleteAccessTokenHeader();
        dispatch(logoutSuccess());
        history.push('/');
    } catch (error) {
        console.log('logout error');
    }
};

const getOAuthUrlRequest = (): authActionTypes => {
    return { type: GET_OAUTH_URL_REQUEST, payload: undefined };
};
const getOAuthUrlSuccess = (payload: any): authActionTypes => {
    return { type: GET_OAUTH_URL_SUCCESS, payload };
};
const getOAuthUrlFailure = (payload: any): authActionTypes => {
    return { type: GET_OAUTH_URL_FAILURE, payload: undefined };
};

export const getOAuthUrl = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
    dispatch(getOAuthUrlRequest());
    try {
        const response = await axios.get(OAUTH_URL);
        dispatch(getOAuthUrlSuccess(response.data));
    } catch (error) {
        dispatch(getOAuthUrlFailure(error));
    }
};
